<template>
  <b-input-group class="input-group-merge">
    <b-form-input :id="'percent'+id" :ref="'percent'+id" min="0.01" v-model.lazy="price" v-money="money" @blur="update" :disabled="disabled" />
  </b-input-group>
</template>

<script>
import { BFormInput, BInputGroup } from 'bootstrap-vue'
import { VMoney } from 'v-money'
import formatter from '@/mixins/formatter'

export default {
  components: {
    BFormInput,
    BInputGroup,
  },
  mixins:[formatter],
  directives: { money: VMoney },
  props: ['value', 'disabled', 'id'],
  emits:['update'],
  data() {
    return {
      price: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  methods: {
    update($event) {
      this.$emit('update', formatter.formatMaskMoney($event.currentTarget._value))
    }
  },

  watch: {
    value: {
      handler(val) {
        const el = document.getElementById('percent'+this.id)
        if(el === null){
          val = val * 100
        }
        this.price = val
        const value = this.formatCurrencyPrice(this.price)
        const refss = this.$refs['percent'+this.id]
        el.value = value
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
</style>
