import Vue from 'vue'

const ImmobilesService = {

  createOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/Immobiles/CreateOrEdit', item)
  },

  delete(id) {
    return Vue.prototype.$http.delete(`api/services/app/Immobiles/Delete?Id=${id}`)
  },

  getImmobileForEdit(id) {
    return Vue.prototype.$http.get(`api/services/app/Immobiles/GetImmobileForEdit?Id=${id}`)
  },

  getImmobileForEdit(id) {
    return Vue.prototype.$http.get(`api/services/app/Immobiles/GetImmobileForEdit?Id=${id}`)
  },

  getImmobileDocumentTypes() {
    return Vue.prototype.$http.get('/api/services/app/ImmobileDocumentTypes/GetAll')
  },
}

export default ImmobilesService
