var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('Loader'):_vm._e(),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"validateForm"},[_c('b-row',[_c('b-col',[_c('h3',[_vm._v("Dados do Imóvel")])])],1),(_vm.isAdmRegister)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Construtora","label-for":"projeto-imovel"}},[_c('validation-provider',{attrs:{"name":"Construtora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"projeto-imovel","options":_vm.listCompanies,"disabled":_vm.disableChild === 1,"required":""},on:{"change":function($event){return _vm.getSystemProjects()}},model:{value:(_vm.formData.companyId),callback:function ($$v) {_vm.$set(_vm.formData, "companyId", $$v)},expression:"formData.companyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,438693188)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Projeto/Obra","label-for":"projeto-imovel"}},[_c('validation-provider',{attrs:{"name":"Projeto/Obra","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"projeto-imovel","options":_vm.listProjects,"disabled":_vm.disableChild === 1 || _vm.disableProject === 1 || (_vm.isAdmRegister && _vm.formData.companyId === undefined),"required":""},model:{value:(_vm.formData.projectId),callback:function ($$v) {_vm.$set(_vm.formData, "projectId", $$v)},expression:"formData.projectId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Nome do Imóvel","label-for":"nome-imovel"}},[_c('validation-provider',{attrs:{"name":"Nome do Imóvel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome-imovel","disabled":_vm.disableChild === 1,"placeholder":"Nome do Imóvel","required":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"CEP","label-for":"cep-imovel"}},[_c('validation-provider',{attrs:{"name":"CEP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"}],attrs:{"id":"cep-imovel","type":"text","disabled":_vm.disableChild === 1,"placeholder":"99.999-99"},model:{value:(_vm.formData.cepNumber),callback:function ($$v) {_vm.$set(_vm.formData, "cepNumber", $$v)},expression:"formData.cepNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Rua","label-for":"rua-imovel"}},[_c('validation-provider',{attrs:{"name":"Rua","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rua-imovel","disabled":_vm.disableChild === 1,"placeholder":"Rua x"},model:{value:(_vm.formData.streetName),callback:function ($$v) {_vm.$set(_vm.formData, "streetName", $$v)},expression:"formData.streetName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Número","label-for":"numero-imovel"}},[_c('validation-provider',{attrs:{"name":"Número","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"numero-imovel","type":"number","disabled":_vm.disableChild === 1,"placeholder":"999"},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Complemento","label-for":"comp-imovel"}},[_c('validation-provider',{attrs:{"name":"Complemento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"comp-imovel","disabled":_vm.disableChild === 1,"placeholder":"Complemento do endereço"},model:{value:(_vm.formData.complement),callback:function ($$v) {_vm.$set(_vm.formData, "complement", $$v)},expression:"formData.complement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Bairro","label-for":"bairro-imovel"}},[_c('validation-provider',{attrs:{"name":"Bairro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bairro-imovel","disabled":_vm.disableChild === 1,"placeholder":"Bairro do imóvel"},model:{value:(_vm.formData.districtName),callback:function ($$v) {_vm.$set(_vm.formData, "districtName", $$v)},expression:"formData.districtName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Cidade","label-for":"cidade-imovel"}},[_c('validation-provider',{attrs:{"name":"Cidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cidade-imovel","disabled":_vm.disableChild === 1,"placeholder":"Nome da Cidade"},model:{value:(_vm.formData.cityName),callback:function ($$v) {_vm.$set(_vm.formData, "cityName", $$v)},expression:"formData.cityName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"estado-imovel"}},[_c('validation-provider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"account-estado","label":"title","options":_vm.listUf,"disabled":_vm.disableChild === 1},model:{value:(_vm.formData.estateId),callback:function ($$v) {_vm.$set(_vm.formData, "estateId", $$v)},expression:"formData.estateId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Número da Unidade","label-for":"unidade-imovel"}},[_c('validation-provider',{attrs:{"name":"Número da Unidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"unidade-imovel","disabled":_vm.disableChild === 1,"placeholder":"999AAA"},model:{value:(_vm.formData.unitNumber),callback:function ($$v) {_vm.$set(_vm.formData, "unitNumber", $$v)},expression:"formData.unitNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área Total m²","label-for":"area-total-imovel"}},[_c('validation-provider',{attrs:{"name":"Area Total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area-total-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"90"},model:{value:(_vm.formData.totalAreaSize),callback:function ($$v) {_vm.$set(_vm.formData, "totalAreaSize", $$v)},expression:"formData.totalAreaSize"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área comum m²","label-for":"area-comum-imovel"}},[_c('validation-provider',{attrs:{"name":"Area Comum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area-comum-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"90"},model:{value:(_vm.formData.commonAreaSize),callback:function ($$v) {_vm.$set(_vm.formData, "commonAreaSize", $$v)},expression:"formData.commonAreaSize"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área Privada m²","label-for":"area-imovel"}},[_c('validation-provider',{attrs:{"name":"Area Privada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"90"},model:{value:(_vm.formData.privateAreaSize),callback:function ($$v) {_vm.$set(_vm.formData, "privateAreaSize", $$v)},expression:"formData.privateAreaSize"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Metragem extenso","label-for":"extensive-footage"}},[_c('validation-provider',{attrs:{"name":"Metragem extenso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"extensive-footage","placeholder":"Metragem extenso","disabled":_vm.disableChild === 1},model:{value:(_vm.formData.extensiveFootage),callback:function ($$v) {_vm.$set(_vm.formData, "extensiveFootage", $$v)},expression:"formData.extensiveFootage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Tipo do Imóvel","label-for":"tipo-imovel"}},[_c('validation-provider',{attrs:{"name":"Tipo do Imóvel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"tipo-estado","label":"title","options":_vm.ImmobileTypes,"disabled":_vm.disableChild === 1},model:{value:(_vm.formData.immobileTypeId),callback:function ($$v) {_vm.$set(_vm.formData, "immobileTypeId", $$v)},expression:"formData.immobileTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Qtd Suítes","label-for":"suites-imovel"}},[_c('validation-provider',{attrs:{"name":"Qtd Suítes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"suites-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"2"},model:{value:(_vm.formData.suiteCount),callback:function ($$v) {_vm.$set(_vm.formData, "suiteCount", $$v)},expression:"formData.suiteCount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Qtd Dormitórios","label-for":"dormitorios-imovel"}},[_c('validation-provider',{attrs:{"name":"Qtd Dormitórios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dormitorios-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"3"},model:{value:(_vm.formData.bedroomCount),callback:function ($$v) {_vm.$set(_vm.formData, "bedroomCount", $$v)},expression:"formData.bedroomCount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Qtd Banheiros","label-for":"banheiros-imovel"}},[_c('validation-provider',{attrs:{"name":"Qtd Banheiros","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"banheiros-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"2"},model:{value:(_vm.formData.bathroomCount),callback:function ($$v) {_vm.$set(_vm.formData, "bathroomCount", $$v)},expression:"formData.bathroomCount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Qtd Lavabo","label-for":"lavabo-imovel"}},[_c('validation-provider',{attrs:{"name":"Qtd Lavabo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lavabo-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"1"},model:{value:(_vm.formData.toiletCount),callback:function ($$v) {_vm.$set(_vm.formData, "toiletCount", $$v)},expression:"formData.toiletCount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"N° Andares","label-for":"andares-imovel"}},[_c('validation-provider',{attrs:{"name":"N° Andares","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"andares-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"3"},model:{value:(_vm.formData.floorCount),callback:function ($$v) {_vm.$set(_vm.formData, "floorCount", $$v)},expression:"formData.floorCount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Vagas de Garagem","label-for":"garagem-imovel"}},[_c('validation-provider',{attrs:{"name":"Vagas de Garagem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"garagem-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"3"},model:{value:(_vm.formData.parkingCount),callback:function ($$v) {_vm.$set(_vm.formData, "parkingCount", $$v)},expression:"formData.parkingCount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área de Garagem Privativa m²","label-for":"area-comum-imovel"}},[_c('validation-provider',{attrs:{"name":"Área de Garagem Privativa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area-comum-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"90"},model:{value:(_vm.formData.areaPrivateGarage),callback:function ($$v) {_vm.$set(_vm.formData, "areaPrivateGarage", $$v)},expression:"formData.areaPrivateGarage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área de Garagem Total m²","label-for":"area-comum-imovel"}},[_c('validation-provider',{attrs:{"name":"Área de Garagem Total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area-comum-imovel","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"90"},model:{value:(_vm.formData.areaTotalGarage),callback:function ($$v) {_vm.$set(_vm.formData, "areaTotalGarage", $$v)},expression:"formData.areaTotalGarage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Número Box","label-for":"garagem-imovel"}},[_c('validation-provider',{attrs:{"name":"Número Box","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"number-box","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"3"},model:{value:(_vm.formData.boxNumber),callback:function ($$v) {_vm.$set(_vm.formData, "boxNumber", $$v)},expression:"formData.boxNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área de Box Privativa m²","label-for":"area-box-private"}},[_c('validation-provider',{attrs:{"name":"Área de Box Privativa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area-box-private","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"90"},model:{value:(_vm.formData.areaPrivateBox),callback:function ($$v) {_vm.$set(_vm.formData, "areaPrivateBox", $$v)},expression:"formData.areaPrivateBox"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área de Box Total m²","label-for":"area-box-total"}},[_c('validation-provider',{attrs:{"name":"Área de Box Total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area-box-total","type":"number","min":"0","disabled":_vm.disableChild === 1,"placeholder":"90"},model:{value:(_vm.formData.areaTotalBox),callback:function ($$v) {_vm.$set(_vm.formData, "areaTotalBox", $$v)},expression:"formData.areaTotalBox"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área de Serviço","label-for":"servico-imovel"}},[_c('validation-provider',{attrs:{"name":"Área de Serviço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"servico-imovel","options":_vm.options,"disabled":_vm.disableChild === 1},model:{value:(_vm.formData.hasServiceArea),callback:function ($$v) {_vm.$set(_vm.formData, "hasServiceArea", $$v)},expression:"formData.hasServiceArea"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Closet","label-for":"closet-imovel"}},[_c('validation-provider',{attrs:{"name":"Closet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"closet-imovel","options":_vm.options,"disabled":_vm.disableChild === 1},model:{value:(_vm.formData.hasCloset),callback:function ($$v) {_vm.$set(_vm.formData, "hasCloset", $$v)},expression:"formData.hasCloset"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Piscina Privativa","label-for":"piscina-imovel"}},[_c('validation-provider',{attrs:{"name":"Piscina Privativa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"piscina-imovel","options":_vm.options,"disabled":_vm.disableChild === 1},model:{value:(_vm.formData.hasPrivatePool),callback:function ($$v) {_vm.$set(_vm.formData, "hasPrivatePool", $$v)},expression:"formData.hasPrivatePool"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Quarto de Empregada","label-for":"empregada-imovel"}},[_c('validation-provider',{attrs:{"name":"Quarto de Empregada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"empregada-imovel","options":_vm.options,"disabled":_vm.disableChild === 1},model:{value:(_vm.formData.hasMaidRoom),callback:function ($$v) {_vm.$set(_vm.formData, "hasMaidRoom", $$v)},expression:"formData.hasMaidRoom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Churrasqueira","label-for":"churrasqueira-imovel"}},[_c('validation-provider',{attrs:{"name":"Churrasqueira","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"churrasqueira-imovel","options":_vm.options,"disabled":_vm.disableChild === 1},model:{value:(_vm.formData.hasBarbecue),callback:function ($$v) {_vm.$set(_vm.formData, "hasBarbecue", $$v)},expression:"formData.hasBarbecue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Área de Máquinas","label-for":"maquinas-imovel"}},[_c('validation-provider',{attrs:{"name":"Área de Máquinas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"maquinas-imovel","options":_vm.options,"disabled":_vm.disableChild === 1},model:{value:(_vm.formData.hasMachineryArea),callback:function ($$v) {_vm.$set(_vm.formData, "hasMachineryArea", $$v)},expression:"formData.hasMachineryArea"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Entrega das chaves","label-for":"key-delivery"}},[_c('validation-provider',{attrs:{"name":"Prazo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.formData.keyDelivery),callback:function ($$v) {_vm.$set(_vm.formData, "keyDelivery", $$v)},expression:"formData.keyDelivery"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Remuneração Esperada","label-for":"remuneracao-imovel"}},[_c('validation-provider',{attrs:{"name":"Remuneração Esperada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money-input',{ref:"expectedRemuneration",attrs:{"id":"expectedRemuneration","value":_vm.formData.expectedRemuneration},on:{"update":_vm.updateExpectedRemuneration}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.deliberation === true || _vm.publication  === true)?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Total de Cotas","label-for":"cotas-imovel"}},[_c('validation-provider',{attrs:{"name":"Total de Cotas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cotas-imovel","type":"number","min":"1","placeholder":"2500"},model:{value:(_vm.formData.quotaCount),callback:function ($$v) {_vm.$set(_vm.formData, "quotaCount", $$v)},expression:"formData.quotaCount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1443214460)})],1)],1):_vm._e(),(_vm.deliberation === true || _vm.publication  === true)?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Valor da Cota","label-for":"cota-imovel"}},[_c('validation-provider',{attrs:{"name":"Valor da Cota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money-input',{ref:"quotaValue",attrs:{"id":"quotaValue","value":_vm.formData.quotaValue},on:{"update":_vm.updateQuotaValue}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,387537120)})],1)],1):_vm._e(),(_vm.deliberation === true || _vm.publication  === true)?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"% de valorização","label-for":"valorization-percent-contract"}},[_c('validation-provider',{attrs:{"name":"% de valorização","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',[_vm._v("%")])]),_c('b-form-input',{attrs:{"id":"valorization-percent-contract","placeholder":"0,00"},model:{value:(_vm.formData.valorizationPercentContract),callback:function ($$v) {_vm.$set(_vm.formData, "valorizationPercentContract", $$v)},expression:"formData.valorizationPercentContract"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3052866794)})],1)],1):_vm._e(),(_vm.deliberation === true || _vm.publication  === true)?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Meses para Entrega","label-for":"months-delivery"}},[_c('validation-provider',{attrs:{"name":"Meses Para Entrega","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"months-delivery","type":"number","min":"1","placeholder":"25"},model:{value:(_vm.formData.monthsDeliveryContract),callback:function ($$v) {_vm.$set(_vm.formData, "monthsDeliveryContract", $$v)},expression:"formData.monthsDeliveryContract"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1906938509)})],1)],1):_vm._e(),(_vm.deliberation === true || _vm.publication  === true)?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Meses Após Entrega","label-for":"months-after-delivery"}},[_c('validation-provider',{attrs:{"name":"Meses Após Entrega","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"months-after-delivery","type":"number","min":"1","placeholder":"25"},model:{value:(_vm.formData.monthsAfterDeliveryContract),callback:function ($$v) {_vm.$set(_vm.formData, "monthsAfterDeliveryContract", $$v)},expression:"formData.monthsAfterDeliveryContract"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1541586624)})],1)],1):_vm._e(),(_vm.deliberation === true || _vm.publication  === true)?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Prazo venda 50% das cotas","label-for":"prazo-imovel-50"}},[_c('validation-provider',{attrs:{"name":"Prazo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.formData.quotaDeadlineSell50),callback:function ($$v) {_vm.$set(_vm.formData, "quotaDeadlineSell50", $$v)},expression:"formData.quotaDeadlineSell50"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4123743205)})],1)],1):_vm._e(),_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Valores Recebidos","label-for":"valores-recebidos","hidden":!_vm.isAdmin}},[_c('validation-provider',{attrs:{"name":"Valores Recebidos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money-input',{ref:"valueReceived",attrs:{"id":"valueReceived","value":_vm.formData.valueReceived},on:{"update":_vm.updateValueReceived}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),((_vm.publication || _vm.formData.publishDate) && _vm.isAdm())?_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"label":"Data de Publicação","label-for":"date-publish"}},[_c('validation-provider',{attrs:{"name":"Data de Publicação","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"id":"date-publish","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.formData.publishDate),callback:function ($$v) {_vm.$set(_vm.formData, "publishDate", $$v)},expression:"formData.publishDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,824854036)})],1)],1):_vm._e(),_c('b-col',{attrs:{"sm":"2"}},[(_vm.deliberation === true && _vm.isAdm())?_c('b-form-group',{attrs:{"label":"Rentabilidade Alvo (% a.a)","label-for":"area-comum-imovel"}},[_c('validation-provider',{attrs:{"name":"Rentabilidade Alvo (% a.a)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('percentual-input',{ref:"estimatedProfitability",attrs:{"id":"estimatedProfitability","value":_vm.formData.estimatedProfitability},on:{"update":_vm.updateEstimatedProfitability}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,534020051)})],1):_vm._e()],1)],1),(_vm.deliberation === true || _vm.publication  === true)?_c('div',[_c('b-row',{staticClass:"pt-2"},[_c('b-col',[_c('h4',[_vm._v("Deliberação do Imóvel")])])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status-imovel"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status-imovel","options":_vm.situation},model:{value:(_vm.formData.situation),callback:function ($$v) {_vm.$set(_vm.formData, "situation", $$v)},expression:"formData.situation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,986367317)})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Rating Imóvel","label-for":"rating-imovel"}},[_c('validation-provider',{attrs:{"name":"Rating","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"rating-imovel","options":_vm.ratings},model:{value:(_vm.formData.immobileRatingId),callback:function ($$v) {_vm.$set(_vm.formData, "immobileRatingId", $$v)},expression:"formData.immobileRatingId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4060328636)})],1)],1)],1),(_vm.deliberation === true || _vm.publication === true)?_c('div',[_c('b-row',{staticClass:"pt-2"},[_c('b-col',[_c('h4',[_vm._v("Status do Imóvel")])])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status-imovel"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status-imovel","options":_vm.status},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3742282133)})],1)],1)],1)],1):_vm._e(),_c('b-row',{staticStyle:{"margin-top":"2em"}},[(_vm.formData !== undefined)?_c('ImmobileMarketPrice',{attrs:{"immobileId":_vm.formData.id}}):_vm._e()],1),_c('b-row',{staticStyle:{"margin-top":"2em"}},[(_vm.formData !== undefined)?_c('ComparativeProfitabilities',{attrs:{"immobileId":_vm.formData.id}}):_vm._e()],1),_c('b-row',[_c('b-col',[_c('span',[_vm._v("Parecer")]),_c('text-editor',{ref:"seem_editor_parecer",attrs:{"text":_vm.formData.seem}})],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"pt-2"},[_c('b-col',[_c('FormDadosImovelCadastro',{ref:"data_form_external",attrs:{"text":_vm.formData.essentialInformation,"data_file_external":_vm.externalFileData,"disable":_vm.disableChild === 1,"document_status":_vm.immobileDocumentStatus,"company_about":_vm.companyAbout,"deliberation":_vm.deliberation,"notify":_vm.notify}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }