<template>    
<div style="width: 100%">
    <hr/>
    <div style="display: flex">
        <div class="titleTab">Rentabilidade Comparada</div>
        <div style="width: 50%">
            <b-button class="buttonAdd" @click="$refs['modal'].show()" size="sm" variant="primary">Adicionar</b-button>
        </div>
    </div>
     <vue-good-table
      id="table_comparatives"
      :columns="listFields"
      :rows="prices"
      :rtl="direction"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
        <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'data'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.data }}</span>
            </span>

            <span v-else-if="props.column.field === 'conservative'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.conservative }}%</span>
            </span>

            <span v-else-if="props.column.field === 'realistic'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.realistic }}%</span>
            </span>

            <span v-else-if="props.column.field === 'optimistic'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.optimistic }}%</span>
            </span>

            <span v-else-if="props.column.field === 'actions'" class="text-nowrap" @click="showModalConfirm(props.row)">
                <b-link title="Excluir">
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                </b-link>
            </span>
        </template>        
        <template slot="emptystate" styleClass="table_empty_data">
            <div class="table_empty_data">
            Sem dados
            </div>
        </template>
      <!-- pagination -->
        <template  id="pagination" slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap pagination">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Mostrando </span>
                <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                    value => props.perPageChanged({ currentPerPage: value })
                " />
                <span class="text-nowrap"> de {{ totalCount }} Resultados </span>
            </div>
            <div>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalCount"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })">
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                </b-pagination>
            </div>
            </div>
        </template>
     </vue-good-table>
     <hr/>
    <b-modal ref="modal" centered title="Adicionar Rentabilidade Comparada" ok-title="OK" cancel-title="Cancelar" @ok="save">   
        <validation-observer ref="validateForm">     
            <b-form-group label="Data" label-for="name">
                <validation-provider #default="{ errors }" name="Data" rules="required">
                    <b-form-input id="date" v-model="item.date" type="date" placeholder="12/04/2021" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>       
            <b-form-group label="Cenário Conservador" label-for="name">
                <validation-provider #default="{ errors }" name="Cenário Conservador" rules="required">
                    <b-form-input id="conservative" type="number" v-model="item.conservative" placeholder="2,5%" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
            <b-form-group label="Cenário Realista" label-for="name">
                <validation-provider #default="{ errors }" name="Cenário Realista" rules="required">
                    <b-form-input id="realistic" type="number" v-model="item.realistic" placeholder="2,5%" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
            <b-form-group label="Cenário Otimista" label-for="name">
                <validation-provider #default="{ errors }" name="Cenário Otimista" rules="required">
                    <b-form-input id="optimistic" type="number" v-model="item.optimistic" placeholder="2,5%" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </validation-observer>
    </b-modal>
    <b-modal id="modal-comparative-confirm-delete" @ok="deletePrice">Confirma exclusão do valor referente a {{priceDelete.date}}</b-modal>
</div>
</template>



<script>
import { BCard, BButton, BTable, BLink, BFormGroup, BInputGroup, BFormInput, BPagination, BFormSelect, BModal } from 'bootstrap-vue'
import ComparativeProfitabilitieService from '@/services/Investment/ComparativeProfitabilitieService'
import formatter from '@/mixins/formatter'
import toast from '@/mixins/toast'
import MoneyInput from '@/components/MoneyInput.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'

import store from '@/store/index'

export default {
    
   components: {
    MoneyInput,
    VueGoodTable,
    BCard,
    BLink,
    BButton,
    BTable,
    BInputGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BModal,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins:[formatter, toast],

  data() {
    return {
      prices:[],
      priceDelete: {item: {}},
      listFields: [
          { field: 'date', label: 'Data' },
        { field: 'conservative', label: 'Cenário Conservador %' },
        { field: 'realistic', label: 'Cenário Realista %' },
        { field: 'optimistic', label: 'Cenário Otimista %' },
          { field: 'actions', label: '' },
      ],
      item: {},
      pageLength: 12,
      currentPage: 1,
      totalCount: 0,
    }
  },

  computed:{
    direction() {
        if (store.state.appConfig.isRTL) {
            this.dir = true
            return this.dir
        }
        this.dir = false
        return this.dir
        },
  },
  props:['immobileId'],

  methods:{
    async getValues(){
        var filter = 'immobileId='+this.immobileId
        const result = (await ComparativeProfitabilitieService.getAll(filter)).data.result
        this.prices = result.items
        this.totalCount = result.totalCount
    },
    async save(){
        const valid = (await this.$refs.validateForm.validate())
        if(valid){
            try {
                this.item.immobileId = this.immobileId
                await ComparativeProfitabilitieService.createOrEdit(this.item) 
                this.item = {}    
                await this.getValues()   
            } catch(error) {
                this.msgError(error.response.data.error.message)
            }
        }
    },
    updateValue(value){
      this.valueInserted = value
    },
    showModalConfirm(price){
      this.priceDelete = price     
      this.$bvModal.show('modal-comparative-confirm-delete')
    },
    async deletePrice(){        
        await ComparativeProfitabilitieService.delete(this.priceDelete.id)
        await this.getValues() 
    }

  },

  mounted(){
    this.getValues()
  }
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.buttonAdd {
    float: right;
    margin-right: 1%;
}
#table_comparatives th{
    text-align: center;
}

#table_comparatives td{
    text-align: center;
}

#table_comparatives th:nth-child(1) { min-width: 15em  !important;  }
#table_comparatives th:nth-child(2) { min-width: 15em  !important;  }
#table_comparatives th:nth-child(3) { min-width: 15em  !important;  }
#table_comparatives th:nth-child(4) { min-width: 15em  !important;  }

#table_comparatives table tbody td:nth-child(1){ min-width: 17em  !important;  }
#table_comparatives table tbody td:nth-child(2) { min-width: 18em !important;  }
#table_comparatives table tbody td:nth-child(3) { min-width: 18em  !important;  }
#table_comparatives table tbody td:nth-child(4) { min-width: 18em  !important;  }

.titleTab{
  width: 50%;
  font-size: 1.5em;
  margin-left: 0.5%;
}
hr {
    margin-left: 0.5%;
    margin-right: 0.5%;
}
.pagination{
    margin-left: 0.5%;
    margin-right: 0.5%;
}

</style>