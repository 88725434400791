import Vue from 'vue'

const ImmobileMarketPriceService = {

  createOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/ComparativeProfitabilities/CreateOrEdit', item)
  },
  delete(id) {
    return Vue.prototype.$http.delete('/api/services/app/ComparativeProfitabilities/Delete?Id='+id)
  },
  getAll(filters) {
    return Vue.prototype.$http.get('/api/services/app/ComparativeProfitabilities/GetAll?'+filters)
  },
  getHistory(immobileId) {
    return Vue.prototype.$http.get('/api/services/app/ComparativeProfitabilities/GetHistory?immobileId='+immobileId)
  },

}

export default ImmobileMarketPriceService
