<template>
  <b-card>
    <Loader v-if="loading" />
    <ImmobileForm
    ref="immobile_form"
    :form-data="formData"
    :external-file-data="externalFileData"
    @formDataChanged="formDataChanged" />
    <b-row class="pt-1 justify-content-end">
      <b-col sm="2" >
        <b-button  class="btn-block" variant="primary" @click="save()">Salvar</b-button>
      </b-col>

      <b-col sm="2" >
        <b-button  class="btn-block" variant="primary" @click="$router.go(-1)">Voltar</b-button>
      </b-col>
    </b-row>
    <ImmobileComents v-if="formData && formData.id"  :immobileId="formData.id"/>
  </b-card>
</template>

<script>

import { BRow, BButton, BCol, BCard } from 'bootstrap-vue'
import toast from '@/mixins/toast'
import ImmobileForm from '../../ImmobileForm.vue'
import ImmobileComents from '@/views/comments/immobile/ImmobileComents'
import ConstructionCompanyService from '../../../services/ConstructionCompany/ConstructionCompanyService'
import ImmobilePhotosService from '../../../services/Immobile/ImmobilePhotosService'
import ImmobileBluePrintsService from '../../../services/Immobile/ImmobileBluePrintsService'
import Loader from '../../../components/Loader.vue'
import ImmobileDocumentsService from '@/services/Immobile/ImmobileDocumentsService'
import ImmobilesService from '@/services/Immobile/ImmobilesService'
import formatter from '@/mixins/formatter'

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BCard,
    Loader,
    ImmobileForm,
    ImmobileComents,
  },
  data() {
    return {
      loading: false,
      service: null,
      closet: null,
      piscina: null,
      quartoEmp: null,
      churrasqueira: null,
      maquinas: null,
      projetoObra: null,
      options: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],

      formData: {
        // name: '',
        // unitNumber: '',
        // privateAreaSize: '',
        // suiteCount: '',
        // bedroomCount: '',
        // bathroomCount: '',
        // toiletCount: '',
        // floorCount: '',
        // parkingCount: '',
        // hasServiceArea: '',
        // hasCloset: '',
        // hasPrivatePool: '',
        // hasMaidRoom: '',
        // hasBarbecue: '',
        // hasMachineryArea: '',
        expectedRemuneration: 0,
        // essentialInformation: '',
        quotaCount: '',
        quotaValue: null,
        quotaDeadlineSell50: null,
        publishDate: null,
        seem: null,
        immobileTypeId: null,
        immobileAddressId: null,
        immobileDeliberationStatusId: null,
        immobileRatingId: null,
        immobileStatusId: null,
        projectId: null,
        valueReceived: 0,

        cepNumber: '',
        streetName: '',
        number: '',
        complement: '',
        districtName: '',
        cityName: '',
        estateId: '',
        id: this.$route.params.company ? this.$route.params.company.id : null,
      },
      externalFileData: {
        attachments: [],
        bluePrint: [],
        bluePrintAll: [],
        document: [],
      },

      listUf: [],
      listProjects: [],
      ImmobileTypes: [],
      immobileDocumentStatus: [],
      immobileDocumentType: [],
      error: '',
      disableChild: this.$route.params.disable ? 1 : 0,

      companyAbout: '',
    }
  },
  mixins: [toast, formatter],

  mounted() {
    this.validarPermissaoPublicarImovel()
    this.getSystemImmobileDocumentTypes()
  },

  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },

  methods: {
    formDataChanged($event) {
      this.formData = $event
    },

    externalData() {
      this.externalFileData.bluePrintAll = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.bluePrintAll
      this.externalFileData.attachments = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachments
      this.externalFileData.attachmentsDeleted = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachmentsDeleted
      this.externalFileData.bluePrint = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.bluePrint
      this.externalFileData.bluePrintDeleted = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.bluePrintDeleted
      this.externalFileData.document = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.document
      this.externalFileData.documentDeleted = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.documentDeleted

      this.formData.essentialInformation = this.$refs.immobile_form.$refs.data_form_external.formDataChild.essentialInformation.content
    },

    async validarPermissaoPublicarImovel() {
      ConstructionCompanyService.ValidarPermissaoPublicarImovel()
        .then()
        .catch(error => {
          this.msgError(error.response.data.error.message)
          if(error.response.data.error.message.includes('análise'))
            this.$router.go(-1)
          else
            this.$router.push({ name: 'company-account' })
        })
    },

    async registerData() {
      const valid = await this.$refs.immobile_form.validate()
      if (valid) {
        this.externalData()
        this.register()
      } else {
        this.msgError('Preencha todos os campos')
      }
    },

    async register() {
      this.loading = true
      try {
        const config = {
        }
        const ImmobilesAddresses = this.treatImmobileAddresses()
        const responseImmobilesAddresses = await this.$http.post('/api/services/app/ImmobileAddresses/CreateOrEdit', ImmobilesAddresses, config)
        if (responseImmobilesAddresses.data.error) {
          this.error = responseImmobilesAddresses.data.error.message
          this.msgError()
          this.loading = false
          return
        }

        this.formData.immobileAddressId = responseImmobilesAddresses.data.result

        const Immobiles = this.treatImmobiles()
        // console.log(Immobiles)
        const responseImmobiles = await this.$http.post('/api/services/app/Immobiles/CreateOrEdit', Immobiles, config)
        if (responseImmobiles.data.error) {
          this.error = responseImmobiles.data.error.message
          this.msgError()
          this.loading = false
          return
        }

        this.formData.id = responseImmobiles.data.result

        await this.registerFiles()
        await this.deleteFiles()
        this.msgSuccess()
        this.loading = false
        setTimeout(() => {
          // this.$router.push({ name: 'company-immobile-list' })
        }, 2700)
      } catch (error) {
        this.error = error.message
        this.loading = false
        this.msgError(this.error)
      }
    },

    async registerFiles() {
      if (this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachments) {
        const files = await this.treaFileAttachments()
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          await this.registerImmobilePhotos(file)
        }
      }

      if (this.$refs.immobile_form.$refs.data_form_external.dataFormChild.bluePrint) {
        const files = await this.treaFileBluePrint()
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          await this.registerImmobileBluePrints(file)
        }
      }
      if (this.$refs.immobile_form.$refs.data_form_external.dataFormChild.document) {
        const files = this.treaFileDocument()
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          await this.registerImmobileDocument(file)
        }
      }

      if (this.$refs.immobile_form.$refs.data_form_external.dataFormChild.bluePrintAll) {
        const files = this.treaFileBluePrintAll()
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          await this.registerImmobileBluePrintsAll(file)
        }
      }
      await this.$refs.immobile_form.getFiles()
    },

    async deleteFiles() {
      if (this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachmentsDeleted) {
        const ids = this.treaFileAttachmentsDeleted()
        // eslint-disable-next-line no-restricted-syntax
        for (const id of ids) {
          await this.deleteImmobilePhotos(id)
        }
      }

      if (this.$refs.immobile_form.$refs.data_form_external.dataFormChild.bluePrintDeleted) {
        const ids = this.treaFileBluePrintDeleted()
        // eslint-disable-next-line no-restricted-syntax
        for (const id of ids) {
          await this.deleteBluePrints(id)
        }
      }

      if (this.$refs.immobile_form.$refs.data_form_external.dataFormChild.documentDeleted) {
        const ids = this.treaFileDocumentDeleted()
        // eslint-disable-next-line no-restricted-syntax
        for (const id of ids) {
          await this.deleteDocument(id)
        }
      }
      await this.$refs.immobile_form.getFiles()
    },

    async treaFileBluePrint() {
     // console.log('treaFileBluePrint init')
      const response = this.externalFileData.bluePrint.map(res => ({
        name: res.description,
        bluePrint: {
          bytes: res.bytes,
          dataType: res.dataType,
          description: res.description,
        },
        id: res.immobileBluePrintId,
        immobileId: this.formData.id,
      }))
      console.log('treaFileBluePrint fim')
      return response
    },

    treaFileBluePrintDeleted() {
      const response = this.externalFileData.bluePrintDeleted.map(res => res.immobileBluePrintId)
      return response
    },

    treaFileDocumentDeleted() {
      const response = this.externalFileData.documentDeleted.map(res => res.immobileDocumentId)
      return response
    },

    treaFileBluePrintAll() {
      console.log('treaFileBluePrintAll inicio')
      const response = this.externalFileData.bluePrintAll.map(res => ({
        name: res.description,
        bluePrint: {
          bytes: res.bytes,
          dataType: res.dataType,
          description: res.description,
        },
        immobileId: this.formData.id,
        id: res.immobileBluePrintId,
      }))
      console.log('treaFileBluePrintAll fim')
      return response
    },

    async treaFileAttachments() {
      const response = this.externalFileData.attachments.map(res => ({
        name: res.description,
        photo: {
          bytes: res.bytes,
          dataType: res.dataType,
          description: res.description,
        },
        immobileId: this.formData.id,
        id: res.immobilePhotoId,
      }))
      return response
    },

    treaFileAttachmentsDeleted() {
      const { attachmentsDeleted } = this.externalFileData
      const response = attachmentsDeleted.map(res => res.immobilePhotoId)
      return response
    },

    treaFileDocument() {
      const docId = this.immobileDocumentType.filter(el => el.name === 'Documentos Comerciais')
      console.log('treaFileDocument init')
      const response = this.externalFileData.document.map(res => ({
        id: res.id,
        name: res.name,
        immobileDocumentTypeId: docId[0].id,
        document: this.makeDocument(res),
        immobileDocumentStatusId: res.immobileDocumentStatusId,
        immobileId: this.formData.id,
      }))
      console.log('treaFileDocument finish')

      return response
    },

    makeDocument(res){
      if(res.document) {
        return {
          bytes: res.document.bytes,
          dataType: res.document.dataType,
          description: res.document.description,
        }
      } else {
        return {
          bytes: res.bytes,
          dataType: res.dataType,
          description: res.description,
        }
      }
    },

    treatImmobiles() {
      const data = {
        name: this.formData.name,
        unitNumber: this.formData.unitNumber,
        privateAreaSize: this.formatInt(this.formData.privateAreaSize),
        suiteCount: this.formatInt(this.formData.suiteCount),
        bedroomCount: this.formatInt(this.formData.bedroomCount),
        bathroomCount: this.formatInt(this.formData.bathroomCount),
        toiletCount: this.formatInt(this.formData.toiletCount),
        floorCount: this.formatInt(this.formData.floorCount),
        parkingCount: this.formatInt(this.formData.parkingCount),
        hasServiceArea: this.formData.hasServiceArea,
        hasCloset: this.formData.hasCloset,
        hasPrivatePool: this.formData.hasPrivatePool,
        hasMaidRoom: this.formData.hasMaidRoom,
        hasBarbecue: this.formData.hasBarbecue,
        hasMachineryArea: this.formData.hasMachineryArea,
        expectedRemuneration: this.formData.expectedRemuneration,
        essentialInformation: this.formData.essentialInformation,
        immobileAddressId: this.formData.immobileAddressId,
        projectId: this.formData.projectId,
        id: this.formData.id,
        immobileTypeId: this.formData.immobileTypeId,
        totalAreaSize: parseFloat(this.formData.totalAreaSize),
        commonAreaSize: parseFloat(this.formData.commonAreaSize),
        valorizationPercentContract: 0,
        monthsDeliveryContract: 0,
        monthsAfterDeliveryContract: 0,
        extensiveFootage: this.formData.extensiveFootage,
        keyDelivery: this.formData.keyDelivery,
        areaPrivateGarage: parseFloat(this.formData.areaPrivateGarage),
        areaTotalGarage: parseFloat(this.formData.areaTotalGarage),
        // eslint-disable-next-line radix
        boxNumber: parseInt(this.formData.boxNumber),
        areaPrivateBox: parseFloat(this.formData.areaPrivateBox),
        areaTotalBox: parseFloat(this.formData.areaTotalBox),
        valueReceived: 0,
        // quotaCount: this.formData.quotaCount,
        // quotaValue: this.formData.quotaValue,
        // quotaDeadlineSell50: this.formData.quotaDeadlineSell50,
        // publishDate: this.formData.publishDate,
        // seem: this.formData.seem,
        // immobileDeliberationStatusId: this.formData.immobileDeliberationStatusId,
        // immobileRatingId: this.formData.immobileRatingId,
        // immobileStatusId: this.formData.immobileStatusId,
      }

      return data
    },
    treatImmobileAddresses() {
      const data = {
        cepNumber: this.number(this.formData.cepNumber),
        streetName: this.formData.streetName,
        number: this.formData.number,
        complement: this.formData.complement,
        districtName: this.formData.districtName,
        cityName: this.formData.cityName,
        estateId: this.formData.estateId,
        id: this.formData.immobileAddressId,
      }

      return data
    },

    async registerImmobilePhotos(data) {
      // console.log(data)
      const response = await ImmobilePhotosService.createOrEdit(data)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },
    async deleteImmobilePhotos(id) {
      // console.log(data)
      const response = await ImmobilePhotosService.delete(id)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },
    async registerImmobileBluePrints(data) {
      const response = await ImmobileBluePrintsService.createOrEdit(data)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },
    async deleteBluePrints(id) {
      // console.log(data)
      const response = await ImmobileBluePrintsService.delete(id)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },
    async deleteDocument(id) {
      // console.log(data)
      const response = await ImmobileDocumentsService.delete(id)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },
    async registerImmobileBluePrintsAll(data) {
      const response = await ImmobileBluePrintsService.createOrEdit(data)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },
    async registerImmobileDocument(data, config) {
      const response = (await ImmobileDocumentsService.createOrEdit(data))
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },    

    async getSystemImmobileDocumentTypes() {
      const r = (await ImmobilesService.getImmobileDocumentTypes())

      this.immobileDocumentType = r.data.result.items.map(res => ({
        id: res.immobileDocumentType.id,
        code: res.immobileDocumentType.code,
        name: res.immobileDocumentType.name,
      }))
    },

    async save() {
      await this.registerData()
    },

    number(v) {
      const r = v.toString().replace(/([^\d])+/gim, '')
      return r
    },

    formatIntBd(v) {
      return v //.replace(/[^\d,]+/g, '').replace(',', '.')
    },
    formatInt(v) {
      // eslint-disable-next-line radix
      return parseInt(v)
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/views/sass/variables.scss';
@import '@/views/sass/company';
body {
  background-color: #f8f8f8 !important;
}
.ft-size {
  font-size: 12px !important;
}
.border-light {
  border: 1px solid #e5e5e5 !important;
}
.custon-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #00d999 !important;
}
.nav-pills .nav-link.active {
  box-shadow: 0 4px 18px -4px hsl(162deg 100% 43% / 65%) !important;
}
.btn-primary,
.btn-primary:focus {
  border-color: var(--color) !important;
  background-color: var(--color) !important;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 4px 18px -4px #00db9aa6 !important;
}
div.company-nav-link a.active {
  color: #fff !important;
}
.form-control:focus {
  background-color: #fff;
  border-color: var(--color) !important;
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
.custom-select:focus {
  border-color: var(--color) !important;
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: var(--color) !important;
  box-shadow: none;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
}
.custom-select:disabled {
  color: #3e3e40;
}
</style>
