<template>
<draggable
tag="ul"
class="drag_primary text-center mt-2"

        :list="values"
        :disabled="false"
        ghost-class="ghost"
        :move="teste"
        @start="true"
        @end="false"

>
    <b-col
        v-for="(item, key) in values"
        :key="key"
        sm="2"
        class="pb-1 drag_position">

        <div v-if="item.id">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="btn-icon rounded-circle remove-img" block variant="danger" size="sm" @click="removeImage(item, key)">
              <feather-icon icon="XIcon" />
          </b-button>
          <b-link class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: item.id, data: item, fileKey: 'filecnpj'})">
              <b-img class="imgt" thumbnail fluid :src="`${item.dataType},${item.bytes}`" alt="image 1" />
          </b-link>
        </div>

        <div v-else>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="btn-icon rounded-circle remove-img" block variant="danger" size="sm" @click="removeImage(item, key)">
              <feather-icon icon="XIcon" />
          </b-button>
              <b-img class="imgt" thumbnail fluid :src="`${item.dataType},${item.bytes}`" alt="image 1" />
          </div>
        </b-col>
    </draggable>
</template>
<script>

import {
  BImg,
  BCol,
  BButton,
  BLink,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import toast from '@/mixins/toast'
import Ripple from 'vue-ripple-directive'
import ImmobilePhotosService from '@/services/Immobile/ImmobilePhotosService'

export default {
  components: {
    BImg,
    BCol,
    BButton,
    // FormRepeater,
    draggable,
    // BListGroupItem
    // BCardGroup,
    BLink,
  },

  directives: {
    Ripple,
  },

  mixins: [toast],
  props: [
    'values',
    'disabled',
  ],
  emits: ['removeImage'],
  data() {
    return {
      address: {},
    }
  },
  methods: {

    async removeImage(image, index) {
      //debugger

      // eslint-disable-next-line no-empty
      if (image.immobilePhotoId) {
        this.$emit('removeImage', image)
        await ImmobilePhotosService.delete(image.immobilePhotoId)
          .then(() => {
            this.values.splice(index, 1)
            this.msgSuccess('Foto Removida')
          })
          .catch(error => this.msgError(error.response.data.error.message))
      }

      this.values.splice(index, 1)
      this.msgSuccess('Foto Removida')
    },

    setDataFile(data) {
      const { id } = data.data
      console.log(id)
      console.log(data)
      localStorage.setItem('fileDataView', JSON.stringify(data))
    },

    teste(event) {
      debugger
    },
  },
}
</script>
