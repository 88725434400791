import Vue from 'vue'

const ImmobilePhotosService = {

  createOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/ImmobilePhotos/CreateOrEdit', item)
  },

  delete(id) {
    return Vue.prototype.$http.delete(`api/services/app/ImmobilePhotos/Delete?Id=${id}`)
  },

  getAll(immobileId) {
    return Vue.prototype.$http.get(`/api/services/app/ImmobilePhotos/GetAll?ImmobileIdFilter=${immobileId}`)
  },
  
  alterOrder(ids) {
    return Vue.prototype.$http.post('/api/services/app/ImmobilePhotos/AlterOrder', ids)
  },
}

export default ImmobilePhotosService
