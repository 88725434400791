<template>    
<div style="width: 100%">
    <hr/>
    <div style="display: flex">
        <div class="titleTab">Preço de Mercado</div>
        <div style="width: 50%">
            <b-button class="buttonAdd" @click="$refs['modal'].show()" size="sm" variant="primary">Adicionar</b-button>
        </div>
    </div>
     <vue-good-table
      id="table_prices"
      :columns="listFields"
      :rows="prices"
      :rtl="direction"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
        <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'userName'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.userName }}</span>
            </span>

            <span v-else-if="props.column.field === 'data'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.data }}</span>
            </span>

            <span v-else-if="props.column.field === 'value'" class="text-nowrap">
                <span class="text-nowrap">{{ formatCurrencyPrice(props.row.value) }}</span>
            </span>
            <span v-else-if="props.column.field === 'actions'" class="text-nowrap" @click="showModalConfirm(props.row)">
                <b-link title="Excluir">
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                </b-link>
            </span>
        </template>        
        <template slot="emptystate" styleClass="table_empty_data">
            <div class="table_empty_data">
            Sem dados
            </div>
        </template>
      <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap pagination">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Mostrando </span>
                <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                    value => props.perPageChanged({ currentPerPage: value })
                " />
                <span class="text-nowrap"> de {{ totalCount }} Resultados </span>
            </div>
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
     </vue-good-table>
    <b-modal ref="modal" centered title="Adicionar preço de Mercado" ok-title="OK" cancel-title="Cancelar" @ok="save">   
        <validation-observer ref="validateForm">     
            <b-form-group label="Data" label-for="name">
                <validation-provider #default="{ errors }" name="Data" rules="required">
                    <b-form-input id="date" v-model="dateInserted" placeholder="12/04/2021" v-mask="'##/##/####'" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>       
            <b-form-group label="Valor" label-for="name">
                <validation-provider #default="{ errors }" name="Valor" rules="required">
                    <money-input :value="valueInserted" @update="updateValue"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </validation-observer>
    </b-modal>
    <b-modal id="modal-confirm-delete" @ok="deletePrice">Confirma exclusão do valor referente a {{priceDelete.date}}</b-modal>
</div>
</template>



<script>
import { BCard, BButton, BTable, BLink, BFormGroup, BInputGroup, BFormInput, BPagination, BFormSelect, BModal } from 'bootstrap-vue'
import ImmobileMarketPriceService from '@/services/Investment/ImmobileMarketPriceService'
import formatter from '@/mixins/formatter'
import toast from '@/mixins/toast'
import MoneyInput from '@/components/MoneyInput.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'

import store from '@/store/index'

export default {
    
   components: {
    MoneyInput,
    VueGoodTable,
    BCard,
    BLink,
    BButton,
    BTable,
    BInputGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BModal,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins:[formatter, toast],

  data() {
    return {
      prices:[],
      priceDelete: {item: {}},
      listFields: [
        { field: 'userName', label: 'Usuário' },
        { field: 'date', label: 'Data' },
        { field: 'value', label: 'Valor' },
          { field: 'actions', label: '' },
      ],
      valueInserted: 0,
      dateInserted: null,
      pageLength: 10,
      currentPage: 1,
      totalCount: 0,
    }
  },

 watch: {
    currentPage: {
      handler(val) {
        this.getValues()
      },
      immediate: true,
      }
  },

  computed:{
    direction() {
        if (store.state.appConfig.isRTL) {
            this.dir = true
            return this.dir
        }
        this.dir = false
        return this.dir
        },
  },
  props:['immobileId'],

  methods:{
    async getValues(){
        var filters = 'immobileId='+this.immobileId
        filters = `${filters}&SkipCount=${(10 * this.currentPage) - 10}`
        const result = (await ImmobileMarketPriceService.getAll(filters)).data.result
        this.prices = result.items
        this.totalCount = result.totalCount
    },
    async save(){
        const valid = (await this.$refs.validateForm.validate())
        if(valid){
            const item = {
                value: this.valueInserted,
                immobileId: this.immobileId,
                date: this.dateInserted
            }
            try {
                await ImmobileMarketPriceService.createOrEdit(item)   
                this.valueInserted = undefined
                this.dateInserted = undefined
                await this.getValues()   
            } catch(error) {
                this.msgError(error.response.data.error.message)
            }
        }
    },
    updateValue(value){
      this.valueInserted = value
    },
    showModalConfirm(price){
      this.priceDelete = price     
      this.$bvModal.show('modal-confirm-delete')
    },
    async deletePrice(){        
        await ImmobileMarketPriceService.delete(this.priceDelete.id)
        await this.getValues() 
    }

  },

  mounted(){
    this.getValues()
  }
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.buttonAdd {
    float: right;
    margin-right: 1%;
}

#table_prices th{
    text-align: center;
}

#table_prices td{
    text-align: center;
}

#table_prices th:nth-child(1) { min-width: 15em  !important; margin-left: 0.5em;  }
#table_prices th:nth-child(2) { min-width: 15em  !important;  }
#table_prices th:nth-child(3) { min-width: 15em  !important;  }
#table_prices th:nth-child(4) { min-width: 15em  !important;  }

#table_prices table tbody td:nth-child(1){ min-width: 17em  !important;  }
#table_prices table tbody td:nth-child(2) { min-width: 18em !important;  }
#table_prices table tbody td:nth-child(3) { min-width: 18em  !important;  }
#table_prices table tbody td:nth-child(4) { min-width: 18em  !important;  }

.titleTab{
  width: 50%;
  font-size: 1.5em;
  margin-left: 0.5%;
}
hr {
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.pagination{
    margin-left: 0.5%;
    margin-right: 0.5%;
}

</style>