import Vue from 'vue'

const ImmobileSignatoryDocumentsService = {

  createOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/ImmobileDocumentSignatories/CreateOrEdit', item)
  },

  delete(id) {
    return Vue.prototype.$http.delete(`api/services/app/ImmobileDocumentSignatories/Delete?Id=${id}`)
  },

  getByDocument(immobileDocumentId) {
    return Vue.prototype.$http.get(`/api/services/app/ImmobileDocumentSignatories/GetByDocumentId?documentId=${immobileDocumentId}`)
  },
}

export default ImmobileSignatoryDocumentsService
