<template>
  <b-tabs justified pills @submit.prevent>
    <Loader v-if="loading"></Loader>
    <b-tab title="Informações Essenciais" active>
      <template #title class="hgh">
        <div v-if="notify.data_essential_notify && !getTextEssential()" class="notify">!</div>
        <span :class="{ notify_input_blank: notify.data_essential_notify && !getTextEssential() }">Informações Essenciais</span>
      </template>

      <b-row>
        <b-col>
          <text-editor ref="data_essential_information" :text="data_essential_information" :disable="disableChild === 1"/>
        </b-col>
      </b-row>
        <small v-if="notify.data_essential_notify && !getTextEssential()" class="text-danger">O campo Informações Essenciais é obrigatório</small>
    </b-tab>

    <b-tab title="Construtora">
      <b-card-text> Descrição da Construtora </b-card-text>
      <b-row>
        <b-col>
          <div v-html="companyAbout" />
        </b-col>
      </b-row>
    </b-tab>

    <b-tab title="Fotos" active>
      <template #title class="hgh">
        <div v-if="validarErroFoto()" class="notify">!</div>
        <span :class="{ notify_input_blank: validarErroFoto()}">Fotos</span>
      </template>

      <b-row>
        <ImgUploadView id="imgView1" :values="dataFormChild.attachments" @removePhoto="removePhoto($event, dataFormChild.attachmentsDeleted)" />
      </b-row>
      <b-row v-if="deliberation === undefined || deliberation === false" class="custon-center">
        <ImgUpload :id="'imgUpload1'" style="margin-bottom: 0rem !important;" @changeFile="getBase64($event, dataFormChild.attachments)" />
      </b-row>
      <small v-if="validarErroFoto()" class="text-danger custon-center">Insira uma foto</small>
    </b-tab>

    <b-tab title="Planta Baixa">
      <template #title class="hgh">
        <div v-if="verificarMsgPlantas()" class="notify">!</div>
        <span :class="{ notify_input_blank: verificarMsgPlantas() }">Planta Baixa</span>
      </template>
      <b-row>
        <b-col> </b-col>
      </b-row>

      <b-row>
        <ImgUploadView id="imgView2" :values="dataFormChild.bluePrint" @removePhoto="removePhoto($event, dataFormChild.bluePrintDeleted)"></ImgUploadView>
      </b-row>

      <b-row class="custon-center" v-if="deliberation === undefined || deliberation === false">
        <ImgUpload :id="'imgUpload2'" style="margin-bottom: 0rem; !important;" @changeFile="getBase64($event, dataFormChild.bluePrint)"></ImgUpload>
      </b-row>
      <small v-if="validarErroPlanta()" class="text-danger custon-center">Insira uma foto</small>

      <b-row>
        <b-col>
          <h4 style="width: -webkit-fill-available">Planta Baixa</h4>
          <h6>Documento contendo todas as plantas</h6>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-link class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({ id: dataFormChild.bluePrintAll[0].id, data: dataFormChild.bluePrintAll[0].bytes })">
            <b-badge v-if="dataFormChild.bluePrintAll && dataFormChild.bluePrintAll.length > 0" variant="primary" class="mt-2 mb-2">
              <feather-icon icon="LinkIcon" class="mr-25" />
              <span>Abrir Planta Baixa</span>
            </b-badge>
          </b-link>
          <b-form-group label="" label-for="search">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="PaperclipIcon" />
                </b-input-group-prepend>
                <b-form-file
                  id="bluePrintAll"
                  ref="bluePrintAll"
                  v-model="file"
                  placeholder="Documento contendo todas as plantas"
                  accept=".pdf"
                  @change="getBase64($event, dataFormChild.bluePrintAll)" />
              </b-input-group>
              <small v-if="validarErroPlantaPdf()" class="text-danger">Insira um arquivo</small>
          </b-form-group>
        </b-col>
      </b-row>
    </b-tab>

    <b-tab title="Documentos">
      <b-row>
        <b-col>
          <b-tabs justified pills>
            <b-tab title="Documentos Comerciais">
              <b-row>
                <!-- draggable -->
                <draggable tag="ul" class="drag_primary text-center mt-2">
                  <b-col v-for="(item, key) in dataFormChild.document" :key="key" sm="2" class="pb-1 drag_position">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="btn-icon rounded-circle remove-img" block variant="danger" size="sm" @click="removeDocumentComercial(item, key)">
                      <feather-icon icon="XIcon" />
                    </b-button>
                    <b-link class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({ id: item.key, data: item })">
                      <b-img class="imgt" thumbnail fluid :src="require('@/assets/images/upload/upload_pdf.png')" alt="image 1" />
                    </b-link>
                    <b-form-input :ref="key" v-model="findImmobileDocument(key).name" type="text" placeholder="Nome do arquivo" :disabled="findImmobileDocument(key).id !== null" />
                    <b-form-select v-if="deliberation" v-model="findImmobileDocument(key).immobileDocumentStatusId" :options="options" />
                  </b-col>
                </draggable>
              </b-row>

              <b-row class="custon-center">
                <!-- card 1 -->
                <b-card img-alt="Card image cap" style="max-width: 15rem" border-variant="light" class="img-size mt-3" no-body>
                  <b-card-body class="title-upload">
                    <b-card-title class="text-center ft-size">Adicionar PDF somente.</b-card-title>
                  </b-card-body>

                  <b-img fluid :src="require('@/assets/images/upload/upload_pdf.png')" />

                  <b-card-body>
                    <b-card-text>
                      <b-form-file id="document" ref="document" placeholder="Enviar" size="sm" accept=".pdf" @change="getBase64Document($event)" />
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </b-row>
            </b-tab>
            <b-tab title="Documentos Contratuais" class="mt-3">
              <div v-if="!deliberation">
                <b-row>
                  <b-col>
                    <span>Os documentos e contratos serão formulados e postados no ato da deliberação do imóvel</span>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-row class="mb-2">
                  <b-col sm="12" class="text-right">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addRow">
                      <span>Adicionar Documento</span>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div>
                      <div v-for="(row, i) in documentosContratuais" :ref="i.id" :key="i.id" class="mb-3" style="border-bottom: 1px solid; border-color: lightgrey">
                        <b-row>
                          <b-col md="5" class="mb-1"> </b-col>
                        </b-row>
                        <b-row style="justify-content: end;">
                          <b-col v-if="row.id !== undefined" md="8" class="mb-1">
                            <h3  style="text-align: right">{{ row.description }}</h3>
                          </b-col>
                          <b-col  v-else md="4" class="mb-1">
                            <b-form-file :id="'doc_' + i" :ref="'doc_' + i" accept=".pdf" placeholder="documento" @change="getBase64DocumentContractual($event, i)" />
                          </b-col>
                        </b-row>

                        <b-row v-for="(item, index) in documentosContratuais[i].signatories" :id="index" :key="item.id">
                          <!-- NOME -->
                          <b-col md="3">
                            <b-form-group label="Nome" label-for="name">
                              <b-form-input v-model="documentosContratuais[i].signatories[index].name" type="text" placeholder="Nome" :disabled="documentosContratuais[i].signatories[index].id !== undefined"/>
                            </b-form-group>
                          </b-col>
                          <!-- CPF -->
                          <b-col md="2">
                            <b-form-group label="CPF" label-for="cpf">
                              <b-form-input
                                v-model="documentosContratuais[i].signatories[index].cpfCnpj"
                                v-mask="['###.###.###-##']"
                                placeholder="000.000.000-00" 
                                @change="validarCpf(documentosContratuais[i].signatories[index])"
                                :disabled="documentosContratuais[i].signatories[index].id !== undefined"/>
                            </b-form-group>
                          </b-col>

                          <!-- E-mail -->
                          <b-col md="2">
                            <b-form-group label="E-mail" label-for="email">
                              <b-form-input v-model="documentosContratuais[i].signatories[index].email" type="text" placeholder="email@email.com.br" :disabled="documentosContratuais[i].signatories[index].id !== undefined"/>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Nascimento" label-for="nascimento">
                              <b-form-input 
                                :id="documentosContratuais[i].signatories[index].idElement"
                                v-mask="['##/##/####']"
                                v-model="documentosContratuais[i].signatories[index].nascimento" 
                                placeholder="Data Nascimento" 
                                @change="changeDate(documentosContratuais[i].signatories[index].idElement)"
                                :disabled="documentosContratuais[i].signatories[index].id !== undefined"/>
                            </b-form-group>
                          </b-col>

                          <!-- Status -->
                          <b-col md="2">
                            <b-form-group label="Status" label-for="status">
                              <b-form-input :value="getStatus(documentosContratuais[i].signatories[index].isSign)" type="text" placeholder="Status" disabled/>
                            </b-form-group>
                          </b-col>

                          <!-- Remove Button -->
                          <b-col md="1" class="mb-50">
                            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="showModaldeleteSignatario(i, index)">
                              <feather-icon icon="Trash2Icon" class="mr-25" />
                              <!-- <span>Delete</span> -->
                            </b-button>
                          </b-col>
                        </b-row>

                        <b-row style="margin-bottom: 1em">
                          <b-col md="6">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" @click="repeateAgain(i)">
                              <!-- <feather-icon icon="PlusIcon" class="mr-25" /> -->
                              <span>Adicionar Novo Signitário</span>
                            </b-button>
                          </b-col>
                          <b-col md="6" class="text-right">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" @click="showModalRemoveDocumentoContratual(i)">
                              <!-- <feather-icon icon="PlusIcon" class="mr-25" /> -->
                              <span>Remover Documento</span>
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-tab>
    <b-modal id="modal-alert-signatario" centered @ok="deleteSignatario()">Confirma a remoção do signarário {{getSignatarioNome()}}</b-modal>
    <b-modal id="modal-alert-documento" centered @ok="deleteDocumentContratual()">Confirma a remoção do documento {{getDocumentoNome()}}</b-modal>
  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormFile,
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BButton,
  BCardText,
  // BListGroupItem,
  BFormInput,
  // BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BLink,
  BBadge,
  BModal,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import TextEditor from '@/views/TextEditor.vue'
import toast from '@/mixins/toast'
import ImgUploadView from './img/ImgUploadView.vue'
import ImgUpload from './img/ImgUpload.vue'
import BinaryObjectsService from '../services/BinaryObjectsService'
import ImmobilesService from '@/services/Immobile/ImmobilesService'
import ImmobileSignatoryDocumentsService from '@/services/Immobile/ImmobileSignatoryDocumentsService'
import { ValidationProvider } from 'vee-validate'
import formatter from '@/mixins/formatter'
import ImmobileDocumentsService from '@/services/Immobile/ImmobileDocumentsService'
import Loader from '../components/Loader.vue'
import ValidationCpfCnpjService from '@/services/ValidationCpfCnpjService'


export default {
  components: {
    BTabs,
    BTab,
    TextEditor,
    BFormFile,
    BImg,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BButton,
    BCardText,
    BFormInput,
    // FormRepeater,
    draggable,
    Loader,
    BCard,
    BCardBody,
    BCardTitle,
    BLink,
    BBadge,
    ImgUploadView,
    ImgUpload,
    ValidationProvider,
    BModal,
  },

  directives: {
    Ripple,
  },

  mixins: [heightTransition, toast],

  props: ['text', 'disable', 'data_file_external', 'document_status', 'company_about', 'id_immobile', 'deliberation'],
  data() {
    return {
      idImmobile: '',
      loading: false,
      formDataChild: {
        essentialInformation: '',
        files: [],
      },
      notify: {},
      immobileDocumentType: [],
      dataFormChild: {
        attachments: [],
        attachmentsDeleted: [],
        bluePrint: [],
        bluePrintDeleted: [],
        bluePrintAll: [],
        bluePrintAllDeleted: [],
        document: [],
        documentDeleted: [],
      },

      file: [],

      options: [],

      data_essential_information: '',
      receiveDataFileExternal: [],
      disableChild: 0,
      immobileDocumentStatus: [],

      companyAbout: '',

      immobileTypes: {
        bluePrint: [],
      },
      items: [],
      nextTodoId: 0,

      // documentosContratuais: [{ items: [] }],
      documentosContratuais: [],
      signatarioDeleteIndex: {},
      signatarioDocumentDeleteIndex: {},
      documentDeleteIndex: {},

    }
  },

  computed: {
    changeData() {
      const { text, disable, data_file_external, document_status, company_about, id_immobile, notify } = this
      return {
        text,
        disable,
        data_file_external,
        document_status,
        company_about,
        id_immobile,
        notify,
      }
    },
  },

  watch: {
    changeData: {
      handler(val) {
        this.data_essential_information = val.text
        this.disableChild = val.disable
        this.receiveDataFileExternal = val.data_file_external
        this.immobileDocumentStatus = val.document_status
        this.options = val.document_status
        this.companyAbout = val.company_about
        this.idImmobile = val.id_immobile
      },
      deep: true,
      immediate: true,
    },

    receiveDataFileExternal: {
      handler(val) {
        if (val) {
          this.getFiles()
        }
      },
      deep: true,
      immediate: true,
    }
  },

  mounted() {
    this.essentialInformation()
    this.getSystemImmobileDocumentTypes()
  },


  methods: {

    getFiles() {
      console.log(`getFiles`)
      this.renderBluePrintImage()
      this.renderBluePrintPdf()
      this.renderPhotosImage()
      this.renderDocumentsComerciais()
      this.renderDocumentsContratuais()
    },

    changeDate(id) {
      formatter.validateDate(id, this)
    },

    validarErroInformacoes() {
      return this.notify.data_essential_notify && !this.getTextEssential()
    },
    validarErroFoto() {
      return this.notify.photos_notify && this.dataFormChild.attachments.length === 0
    },
    validarErroPlanta() {
      return this.notify.bluePrint_notify && this.dataFormChild.bluePrint.length === 0
    },
    validarErroPlantaPdf() {
      return this.notify.bluePrintAll_notify && this.dataFormChild.bluePrintAll.length === 0
    },
    async validarCpf($event) {
      const {result } = (await ValidationCpfCnpjService.validaCpf($event.cpfCnpj)).data
      if(result === false) {
        $event.cpfCnpj = null
        this.msgError('CPF inválido')
      }
    },

    validate() {
      let valid = true
      this.notify.data_essential_notify = false
      this.notify.photos_notify = false
      this.notify.bluePrint_notify = false
      this.notify.bluePrintAll_notify = false
      if (!this.getTextEssential()) {
        this.notify.data_essential_notify = true
        valid = valid ? false :  valid
      }
      if (this.dataFormChild.attachments.length === 0) {
        this.notify.photos_notify = true
        valid = valid ? false :  valid
      }
      if (this.dataFormChild.bluePrint.length === 0) {
        this.notify.bluePrint_notify = true
        valid = valid ? false :  valid
      }
      if (this.dataFormChild.bluePrint.length === 0) {
        this.notify.bluePrintAll_notify = true
        valid = valid ? false :  valid
      }
      return valid
    },

    essentialInformation() {
      this.formDataChild.essentialInformation = this.$refs.data_essential_information
    },
    verificarMsgPlantas() {
      return (this.notify.bluePrint_notify && this.dataFormChild.bluePrint.length === 0) || (this.notify.bluePrintAll_notify && this.dataFormChild.bluePrintAll.length === 0)
    },
    getTextEssential() {
      return this.$refs.data_essential_information.content
    },

    firstOption() {
      return this.options.filter(o => o.code === 0)
    },

    findImmobileDocument(index) {
      return this.receiveDataFileExternal.document[index].immobileDocument
    },

    async getDataUser() {
      const r = await BinaryObjectsService.getAppBinaryObjectsForEdit(this.fileId)
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })
      return r
    },
    async renderBluePrintImage() {
      if (this.receiveDataFileExternal && this.receiveDataFileExternal.bluePrint) {
        const blue = this.receiveDataFileExternal.bluePrint
        const result = []
        const resultAll = []

        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for await (const key of blue) {
          // console.log(key.immobileBluePrint.name)
          if (key.immobileBluePrint.name.search('pdf') < 0) {
            const binary = await this.getBinaryObjects(key.immobileBluePrint.binaryObjectId)
            binary.immobileBluePrintId = key.immobileBluePrint.id
            result.push(binary)
          }

          if (key.immobileBluePrint.name.search('pdf') > 0) {
            const binary = await this.getBinaryObjects(key.immobileBluePrint.binaryObjectId)
            binary.immobileBluePrintId = key.immobileBluePrint.id
            resultAll.push(binary)
          }
        }
        // console.log(result)
        this.dataFormChild.bluePrint = result
        this.dataFormChild.bluePrintAll = resultAll
      } else {
        this.receiveDataFileExternal = []
        this.dataFormChild.bluePrint = this.receiveDataFileExternal
        this.dataFormChild.bluePrintAll = this.receiveDataFileExternal
      }
    },
    async renderBluePrintPdf() {
      if (this.receiveDataFileExternal && this.receiveDataFileExternal.bluePrint) {
        const blue = this.receiveDataFileExternal.bluePrint
        const result = []

        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for await (const key of blue) {
          // console.log(key.immobileBluePrint.name)
          if (key.immobileBluePrint.name.search('pdf') > 0) {
            result.push(await this.getBinaryObjects(key.immobileBluePrint.binaryObjectId))
          }
        }
        this.dataFormChild.bluePrint = result
      }
    },

    async renderDocumentsComerciais() {
      if (this.receiveDataFileExternal && this.receiveDataFileExternal.document) {
        const blue = this.receiveDataFileExternal.document
        const result = []

        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for await (const key of blue) {
          const binary = await this.getBinaryObjects(key.immobileDocument.binaryObjectId)
          binary.immobileDocumentId = key.immobileDocument.id
          result.push(binary)
        }
        this.dataFormChild.document = result
      }
    },

    async renderDocumentsContratuais() {
      if (this.receiveDataFileExternal && this.receiveDataFileExternal.documentosContratuais) {
        const blue = this.receiveDataFileExternal.documentosContratuais
        const result = []

        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for await (const key of blue) {
          const binary = await this.getBinaryObjects(key.immobileDocument.binaryObjectId)
          binary.clicksignDocumentId = key.immobileDocument.clicksignDocumentId
          binary.immobileDocumentId = key.immobileDocument.id
          const signatories = ( await ImmobileSignatoryDocumentsService.getByDocument(binary.immobileDocumentId)).data.result.items
          signatories.forEach(e => e.nascimento = formatter.formatDate(e.nascimento))
          binary.signatories = signatories
          result.push(binary)
        }
        this.documentosContratuais = result
      }
    },

    async getSystemImmobileDocumentTypes() {
      const r = await ImmobilesService.getImmobileDocumentTypes()

      this.immobileDocumentType = r.data.result.items.map(res => ({
        id: res.immobileDocumentType.id,
        code: res.immobileDocumentType.code,
        name: res.immobileDocumentType.name,
      }))
    },

    formatDate(date) {
      return formatter.formatDate()
    },

    async renderPhotosImage() {
      if (this.receiveDataFileExternal.attachments) {
        const files = this.receiveDataFileExternal.attachments
        const result = []

        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for await (const key of files) {
          const binary = await this.getBinaryObjects(key.immobilePhoto.binaryObjectId)
          binary.immobilePhotoId = key.immobilePhoto.id
          result.push(binary)
        }

        console.log(result)
        this.dataFormChild.attachments = result
      }
    },
    async getIdBinaryBluePrint(id) {
      const r = await this.$http
        .get(`/api/services/app/ImmobileBluePrints/GetImmobileBluePrintForEdit?Id=${id}`, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })

      return this.getBinaryObjects(r.data.result.immobileBluePrint.binaryObjectId)
    },
    async getBinaryObjects(id) {
      const r = await BinaryObjectsService.getAppBinaryObjectsForEdit(id)
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })
      // // console.log(r.data.result.appBinaryObjects)
      return r.data.result.appBinaryObjects
    },

    removePhoto($event, list) {
      list.push($event)
    },

    async removeDocumentComercial($event, index) {
      await this.deleteDocument($event.immobileDocumentId)
      this.dataFormChild.document.splice(index, 1)
    },

    async deleteDocument(id) {
      // console.log(data)
      const response = await ImmobileDocumentsService.delete(id)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },

    removePhotoBluePrintAll(image, index) {
      this.dataFormChild.bluePrintAll.splice(index, 1)
      // this.applyImage()
    },

    async getBase64(event, list) {
      const reader = new FileReader()
      reader.onload = e => {
        const arr = {
          bytes: e.target.result.split(',')[1],
          description: event.target.files[0].name,
          dataType: e.target.result.split(',')[0],
        }
        console.log(`getBase64`)
        console.log(arr)
        list.push(arr)
      }
      reader.readAsDataURL(event.target.files[0])
    },

    async getBase64Document(event) {
      const reader = new FileReader()
      const statusId = this.immobileDocumentStatus.filter(el => el.text === 'Confirmado')
      reader.onload = e => {
        const arr = {
          name: '',
          document: {
            bytes: e.target.result.split(',')[1],
            description: event.target.files[0].name,
            dataType: e.target.result.split(',')[0],
          },
          immobileDocumentStatusId: statusId[0].value,
        }
        this.dataFormChild.document.push(arr)
      }
      reader.readAsDataURL(event.target.files[0])
    },

    async getBase64DocumentContractual(event, i) {
      if (this.checkSizeFile(event) !== 1) {
        this.clearFiles(event)
        this.msgWarning('O arquivo deve ter no máximo 1MB')
        return
      }
      const reader = new FileReader()

      reader.onload = e => {
        const arr = {
          name: event.target.files[0].name,
          immobileDocumentTypeId: this.immobileDocumentType.filter(t => t.code === 1)[0].id,
          document: {
            bytes: e.target.result.split(',')[1],
            description: event.target.files[0].name,
            dataType: e.target.result.split(',')[0],
          },
          immobileId: this.idImmobile,
        }
        const docs = this.documentosContratuais
        this.documentosContratuais[i].document.push(arr)
      }
      reader.readAsDataURL(event.target.files[0])
    },

    checkSizeFile(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        return
      }

      // eslint-disable-next-line consistent-return
      return 1
    },

    clearFiles(e) {
      this.$refs[e.target.id][0].reset()
    },

    setDataFile(data) {
      localStorage.setItem('fileDataView', JSON.stringify(data))
    }, 

    repeateAgain(i) {
      this.documentosContratuais[i].signatories.push({idElement: Math.random()})
    },

    getSignatarioNome() {
      try {
        return this.documentosContratuais[this.signatarioDocumentDeleteIndex].signatories[this.signatarioDeleteIndex].name
      } catch {
        return ''
      }
    },

    async showModaldeleteSignatario(r, index) {
      this.signatarioDocumentDeleteIndex = r
      this.signatarioDeleteIndex = index
      const document = this.documentosContratuais[r]
      const sign = document.signatories[index]
      if(sign.id) {
        this.$bvModal.show('modal-alert-signatario')
      } else {
        document.signatories.splice(index, 1)
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
    },

    async deleteSignatario() {
      this.loading = true
      const document = this.documentosContratuais[this.signatarioDocumentDeleteIndex]
      const sign = document.signatories[this.signatarioDeleteIndex]
      await ImmobileSignatoryDocumentsService.delete(sign.id)
        .then(response => {
          document.signatories.splice(this.signatarioDocumentDeleteIndex, 1)
          this.loading = false
          this.msgSuccess('Signatário removido com sucesso')
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
        })
        .catch(error => this.msgError(error.response.data.error.message)) 
      this.loading = false
    },

    addRow() {
      this.documentosContratuais.push({
        signatories: [],
        document: [],
      })
    },
    showModalRemoveDocumentoContratual(row) {
      const document = this.documentosContratuais[row]
      if(document.id){
        this.documentDeleteIndex = row
        this.$bvModal.show('modal-alert-documento')
      } else {
        this.documentosContratuais.splice(row, 1)
      }
    },

     async deleteDocumentContratual() {
      const document = this.documentosContratuais[this.documentDeleteIndex]
      this.loading = true
      await ImmobileDocumentsService.delete(document.immobileDocumentId)
        .then(response => {
          this.documentosContratuais.splice(this.documentDeleteIndex, 1)
          this.msgSuccess("Documento removido com sucesso")
        })
        .catch(error => this.msgError(error.response.data.error.message)) 
      this.loading = false
    },

    getDocumentoNome() {
      try {
        return this.documentosContratuais[this.documentDeleteIndex].description
      } catch {
        return ''
      }
    },

    getStatus(isSigned){
      return isSigned === true ? 'Assinado' : 'Aguardando assinatura'
    }
  },
}
</script>

<style lang="scss">
@import '@/views/sass/variables.scss';
@import '@/views/sass/company';
.inpf {
  height: 100px;
  width: 100px;
}
.imgt {
  height: 150px;
  width: 150px;
}
.drag_position {
  display: inline-block;
  max-width: 180px;
}
.drag_primary {
  padding: 0;
  width: 100%;
}
.remove-img {
  padding: 0px !important;
  width: 26px;
  height: 26px;
  margin-left: 125px;
  position: relative;
  margin-bottom: -26px;
  display: block;
  z-index: 100;
}
div.img-size > img,
div.img-size > a img {
  padding: 10px 35px;
}
.title-upload {
  border-bottom: 1px solid #e5e5e5;
  padding: 22px 0 0 0;
  margin: 0 0 15px 0;
  background-color: #e3e3e3;
}
.ft-size {
  font-size: 12px !important;
}
.border-light {
  border: 1px solid #e5e5e5 !important;
}
.custon-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color) !important;
}
.nav-pills .nav-link.active {
  box-shadow: 0 4px 18px -4px var(--color) !important;
}
.btn-primary,
.btn-primary:focus {
  border-color: var(--color) !important;
  background-color: var(--color) !important;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 4px 18px -4px var(--color) !important;
}
div.company-nav-link a.active {
  color: #fff !important;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: var(--color) !important;
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
.notify {
  position: absolute;
  margin-top: -6px;
  background-color: #ff2e2e;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 20px;
  height: 25px;
  width: 25px;
}
.notify_input_blank {
  color: #ff2e2e;
  font-weight: 700;
}
</style>
