import Vue from 'vue'

const ImmobileDocumentsService = {

  createOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/ImmobileDocuments/CreateOrEdit', item)
  },

  delete(id) {
    return Vue.prototype.$http.delete(`api/services/app/ImmobileDocuments/Delete?Id=${id}`)
  },

  getAll(immobileId) {
    return Vue.prototype.$http.get(`/api/services/app/ImmobileDocuments/GetAll?ImmobileIdFilter=${immobileId}`)
  },
}

export default ImmobileDocumentsService
