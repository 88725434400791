<template>
  <div>
    <Loader v-if="loading"></Loader>
    <b-form @submit.prevent>
      <validation-observer ref="validateForm">
        <b-row>
          <b-col>
            <h3>Dados do Imóvel</h3>
          </b-col>
        </b-row>
        <b-row v-if="isAdmRegister">

          <b-col>
            <b-form-group label="Construtora" label-for="projeto-imovel">
              <validation-provider #default="{ errors }" name="Construtora" rules="required">
                <b-form-select id="projeto-imovel" v-model="formData.companyId" :options="listCompanies" 
                :disabled="disableChild === 1" v-on:change="getSystemProjects()" required />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Projeto/Obra" label-for="projeto-imovel">
              <validation-provider #default="{ errors }" name="Projeto/Obra" rules="required">
                <b-form-select id="projeto-imovel" v-model="formData.projectId" :options="listProjects" :disabled="disableChild === 1 || disableProject === 1 || (isAdmRegister && formData.companyId === undefined)" required/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="4">
            <b-form-group label="Nome do Imóvel" label-for="nome-imovel">
              <validation-provider #default="{ errors }" name="Nome do Imóvel" rules="required">
                <b-form-input id="nome-imovel" v-model="formData.name" :disabled="disableChild === 1" placeholder="Nome do Imóvel" required/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="CEP" label-for="cep-imovel">
              <validation-provider #default="{ errors }" name="CEP" rules="required">
                <b-form-input id="cep-imovel" v-model="formData.cepNumber" v-mask="['#####-###']" type="text" :disabled="disableChild === 1" placeholder="99.999-99" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label="Rua" label-for="rua-imovel">
              <validation-provider #default="{ errors }" name="Rua" rules="required">
                <b-form-input id="rua-imovel" v-model="formData.streetName" :disabled="disableChild === 1" placeholder="Rua x" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Número" label-for="numero-imovel">
              <validation-provider #default="{ errors }" name="Número" rules="required">
                <b-form-input id="numero-imovel" v-model="formData.number" type="number" :disabled="disableChild === 1" placeholder="999" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="4">
            <b-form-group label="Complemento" label-for="comp-imovel">
              <validation-provider #default="{ errors }" name="Complemento" rules="required">
                <b-form-input id="comp-imovel" v-model="formData.complement" :disabled="disableChild === 1" placeholder="Complemento do endereço" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Bairro" label-for="bairro-imovel">
              <validation-provider #default="{ errors }" name="Bairro" rules="required">
                <b-form-input id="bairro-imovel" v-model="formData.districtName" :disabled="disableChild === 1" placeholder="Bairro do imóvel" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group label="Cidade" label-for="cidade-imovel">
              <validation-provider #default="{ errors }" name="Cidade" rules="required">
                <b-form-input id="cidade-imovel" v-model="formData.cityName" :disabled="disableChild === 1" placeholder="Nome da Cidade" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Estado" label-for="estado-imovel">
              <validation-provider #default="{ errors }" name="Estado" rules="required">
                <b-form-select id="account-estado" v-model="formData.estateId" label="title" :options="listUf" :disabled="disableChild === 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <b-form-group label="Número da Unidade" label-for="unidade-imovel">
              <validation-provider #default="{ errors }" name="Número da Unidade" rules="required">
                <b-form-input id="unidade-imovel" v-model="formData.unitNumber" :disabled="disableChild === 1" placeholder="999AAA" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Área Total m²" label-for="area-total-imovel">
              <validation-provider #default="{ errors }" name="Area Total" rules="required">
                <b-form-input id="area-total-imovel" v-model="formData.totalAreaSize" type="number" min="0" :disabled="disableChild === 1" placeholder="90" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Área comum m²" label-for="area-comum-imovel">
              <validation-provider #default="{ errors }" name="Area Comum" rules="required">
                <b-form-input id="area-comum-imovel" v-model="formData.commonAreaSize" type="number" min="0" :disabled="disableChild === 1" placeholder="90" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Área Privada m²" label-for="area-imovel">
              <validation-provider #default="{ errors }" name="Area Privada" rules="required">
                <b-form-input id="area-imovel" v-model="formData.privateAreaSize" type="number" min="0" :disabled="disableChild === 1" placeholder="90" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Metragem extenso" label-for="extensive-footage">
              <validation-provider #default="{ errors }" name="Metragem extenso" rules="required">
                <b-form-input id="extensive-footage" v-model="formData.extensiveFootage" placeholder="Metragem extenso" :disabled="disableChild === 1"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Tipo do Imóvel" label-for="tipo-imovel">
              <validation-provider #default="{ errors }" name="Tipo do Imóvel" rules="required">
                <b-form-select id="tipo-estado" v-model="formData.immobileTypeId" label="title" :options="ImmobileTypes" :disabled="disableChild === 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Qtd Suítes" label-for="suites-imovel">
              <validation-provider #default="{ errors }" name="Qtd Suítes" rules="required">
                <b-form-input id="suites-imovel" v-model="formData.suiteCount" type="number" min="0" :disabled="disableChild === 1" placeholder="2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Qtd Dormitórios" label-for="dormitorios-imovel">
              <validation-provider #default="{ errors }" name="Qtd Dormitórios" rules="required">
                <b-form-input id="dormitorios-imovel" v-model="formData.bedroomCount" type="number"  min="0" :disabled="disableChild === 1" placeholder="3" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Qtd Banheiros" label-for="banheiros-imovel">
              <validation-provider #default="{ errors }" name="Qtd Banheiros" rules="required">
                <b-form-input id="banheiros-imovel" v-model="formData.bathroomCount" type="number" min="0" :disabled="disableChild === 1" placeholder="2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group label="Qtd Lavabo" label-for="lavabo-imovel">
              <validation-provider #default="{ errors }" name="Qtd Lavabo" rules="required">
                <b-form-input id="lavabo-imovel" v-model="formData.toiletCount" type="number" min="0" :disabled="disableChild === 1" placeholder="1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="N° Andares" label-for="andares-imovel">
              <validation-provider #default="{ errors }" name="N° Andares" rules="required">
                <b-form-input id="andares-imovel" v-model="formData.floorCount" type="number" min="0" :disabled="disableChild === 1" placeholder="3" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Vagas de Garagem" label-for="garagem-imovel">
              <validation-provider #default="{ errors }" name="Vagas de Garagem" rules="required">
                <b-form-input id="garagem-imovel" v-model="formData.parkingCount" type="number" min="0" :disabled="disableChild === 1" placeholder="3" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <b-form-group label="Área de Garagem Privativa m²" label-for="area-comum-imovel">
              <validation-provider #default="{ errors }" name="Área de Garagem Privativa" rules="required">
                <b-form-input id="area-comum-imovel" v-model="formData.areaPrivateGarage" type="number" min="0" :disabled="disableChild === 1" placeholder="90" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Área de Garagem Total m²" label-for="area-comum-imovel">
              <validation-provider #default="{ errors }" name="Área de Garagem Total" rules="required">
                <b-form-input id="area-comum-imovel" v-model="formData.areaTotalGarage" type="number" min="0" :disabled="disableChild === 1" placeholder="90" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Número Box" label-for="garagem-imovel">
              <validation-provider #default="{ errors }" name="Número Box" rules="required">
                <b-form-input id="number-box" v-model="formData.boxNumber" type="number" min="0" :disabled="disableChild === 1" placeholder="3" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Área de Box Privativa m²" label-for="area-box-private">
              <validation-provider #default="{ errors }" name="Área de Box Privativa" rules="required">
                <b-form-input id="area-box-private" v-model="formData.areaPrivateBox" type="number" min="0" :disabled="disableChild === 1" placeholder="90" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Área de Box Total m²" label-for="area-box-total">
              <validation-provider #default="{ errors }" name="Área de Box Total" rules="required">
                <b-form-input id="area-box-total" v-model="formData.areaTotalBox" type="number" min="0" :disabled="disableChild === 1" placeholder="90" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Área de Serviço" label-for="servico-imovel">
              <validation-provider #default="{ errors }" name="Área de Serviço" rules="required">
                <b-form-select id="servico-imovel" v-model="formData.hasServiceArea" :options="options" :disabled="disableChild === 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Closet" label-for="closet-imovel">
              <validation-provider #default="{ errors }" name="Closet" rules="required">
                <b-form-select id="closet-imovel" v-model="formData.hasCloset" :options="options" :disabled="disableChild === 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
            <b-col sm="2">
              <b-form-group label="Piscina Privativa" label-for="piscina-imovel">
                <validation-provider #default="{ errors }" name="Piscina Privativa" rules="required">
                  <b-form-select id="piscina-imovel" v-model="formData.hasPrivatePool" :options="options" :disabled="disableChild === 1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Quarto de Empregada" label-for="empregada-imovel">
                <validation-provider #default="{ errors }" name="Quarto de Empregada" rules="required">
                  <b-form-select id="empregada-imovel" v-model="formData.hasMaidRoom" :options="options" :disabled="disableChild === 1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Churrasqueira" label-for="churrasqueira-imovel">
                <validation-provider #default="{ errors }" name="Churrasqueira" rules="required">
                  <b-form-select id="churrasqueira-imovel" v-model="formData.hasBarbecue" :options="options" :disabled="disableChild === 1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Área de Máquinas" label-for="maquinas-imovel">
                <validation-provider #default="{ errors }" name="Área de Máquinas" rules="required">
                  <b-form-select id="maquinas-imovel" v-model="formData.hasMachineryArea" :options="options" :disabled="disableChild === 1" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>    
            <b-col sm="2">
              <b-form-group label="Entrega das chaves" label-for="key-delivery">
                <validation-provider #default="{ errors }" name="Prazo" rules="required">
                <b-form-input
                  v-model="formData.keyDelivery"
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Edicao / visualizacao -->
          <b-row>
            <b-col sm="2">
              <b-form-group label="Remuneração Esperada" label-for="remuneracao-imovel">
                <validation-provider #default="{ errors }" name="Remuneração Esperada" rules="required">
                  <money-input ref="expectedRemuneration" id="expectedRemuneration" :value="formData.expectedRemuneration" @update="updateExpectedRemuneration"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2" v-if="deliberation === true || publication  === true">
              <b-form-group label="Total de Cotas" label-for="cotas-imovel">
                <validation-provider #default="{ errors }" name="Total de Cotas" rules="required">
                  <b-form-input id="cotas-imovel" v-model="formData.quotaCount" type="number" min="1" placeholder="2500" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2" v-if="deliberation === true || publication  === true">
              <b-form-group label="Valor da Cota" label-for="cota-imovel">
                <validation-provider #default="{ errors }" name="Valor da Cota" rules="required">   
                  <money-input ref="quotaValue" id="quotaValue" :value="formData.quotaValue" @update="updateQuotaValue"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2" v-if="deliberation === true || publication  === true">
              <b-form-group label="% de valorização" label-for="valorization-percent-contract">
                <validation-provider #default="{ errors }" name="% de valorização" rules="required">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <span>%</span>
                    </b-input-group-prepend>
                    <b-form-input id="valorization-percent-contract" v-model="formData.valorizationPercentContract" placeholder="0,00" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2" v-if="deliberation === true || publication  === true">
              <b-form-group label="Meses para Entrega" label-for="months-delivery">
                <validation-provider #default="{ errors }" name="Meses Para Entrega" rules="required">
                  <b-form-input id="months-delivery" v-model="formData.monthsDeliveryContract" type="number" min="1" placeholder="25" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2" v-if="deliberation === true || publication  === true">
              <b-form-group label="Meses Após Entrega" label-for="months-after-delivery">
                <validation-provider #default="{ errors }" name="Meses Após Entrega" rules="required">
                  <b-form-input id="months-after-delivery" v-model="formData.monthsAfterDeliveryContract" type="number" min="1" placeholder="25" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2" v-if="deliberation === true || publication  === true">
              <b-form-group label="Prazo venda 50% das cotas" label-for="prazo-imovel-50">
                <validation-provider #default="{ errors }" name="Prazo" rules="required">
                <b-form-input
                  v-model="formData.quotaDeadlineSell50" 
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Valores Recebidos" label-for="valores-recebidos" :hidden="!isAdmin">
                <validation-provider
                #default="{ errors }"
                name="Valores Recebidos"
                >
                <money-input
                id="valueReceived"
                ref="valueReceived"
                :value="formData.valueReceived"
                @update="updateValueReceived" />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> 
            <b-col sm="2" v-if="(publication || formData.publishDate) && isAdm()">
              <b-form-group label="Data de Publicação" label-for="date-publish">
                <validation-provider #default="{ errors }" name="Data de Publicação" rules="required">
                <b-form-input
                  id="date-publish"
                  v-model="formData.publishDate"
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Rentabilidade Alvo (% a.a)" label-for="area-comum-imovel" v-if="deliberation === true && isAdm()">
                <validation-provider #default="{ errors }" name="Rentabilidade Alvo (% a.a)" rules="required">
                  <percentual-input ref="estimatedProfitability" id="estimatedProfitability" :value="formData.estimatedProfitability" @update="updateEstimatedProfitability"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="deliberation === true || publication  === true">
          <b-row class="pt-2">
            <b-col>
              <h4>Deliberação do Imóvel</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <b-form-group label="Status" label-for="status-imovel">
                <validation-provider #default="{ errors }" name="Status" rules="required">
                    <b-form-select id="status-imovel" v-model="formData.situation" :options="situation"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group label="Rating Imóvel" label-for="rating-imovel">
                <validation-provider #default="{ errors }" name="Rating" rules="required">
                    <b-form-select id="rating-imovel" v-model="formData.immobileRatingId" :options="ratings"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
          </b-row>

          <div v-if="deliberation === true || publication === true">
            <b-row class="pt-2">
              <b-col>
                <h4>Status do Imóvel</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-group label="Status" label-for="status-imovel">
                  <validation-provider #default="{ errors }" name="Status" rules="required">
                      <b-form-select id="status-imovel" v-model="formData.status" :options="status"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
               </b-col>
            </b-row>
          </div>

          <b-row style="margin-top: 2em">
            <ImmobileMarketPrice v-if="formData !== undefined" :immobileId="formData.id"/>
          </b-row>
          <b-row style="margin-top: 2em">
            <ComparativeProfitabilities v-if="formData !== undefined" :immobileId="formData.id"/>
          </b-row>
          <b-row>
            <b-col>
              <span>Parecer</span>
              <text-editor ref="seem_editor_parecer" :text="formData.seem" />
            </b-col>
          </b-row>
          </div>
        <b-row class="pt-2">
          <b-col>
            <FormDadosImovelCadastro
              ref="data_form_external"
              :text="formData.essentialInformation"
              :data_file_external="externalFileData"
              :disable="disableChild === 1"
              :document_status="immobileDocumentStatus"
              :company_about="companyAbout"
              :deliberation="deliberation"
              :notify="notify"/>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormInput, BFormGroup, BFormSelect, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue'
import { required, min } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ImmobileMarketPrice from '@/views/admin/immobile/ImmobileMarketPrice.vue'
import ComparativeProfitabilities from '@/views/admin/immobile/ComparativeProfitabilities.vue'
import FormDadosImovelCadastro from './FormDadosImovelCadastro.vue'
import TextEditor from './TextEditor.vue'
import Loader from '../components/Loader.vue'
import MoneyInput from '../components/MoneyInput.vue'
import PercentualInput from '../components/PercentualInput.vue'
import ImmobileDocumentsService from '../services/Immobile/ImmobileDocumentsService'
import ImmobileBluePrintsService from '../services/Immobile/ImmobileBluePrintsService'
import ImmobilePhotosService from '../services/Immobile/ImmobilePhotosService'
import formatter from '@/mixins/formatter'
import {VMoney} from 'v-money'

export default {
  components: {
    Loader,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    FormDadosImovelCadastro,
    TextEditor,
    ValidationProvider,
    ValidationObserver,
    MoneyInput,
    PercentualInput,
    ImmobileMarketPrice,
    ComparativeProfitabilities
  },

  emits: ['formDataChanged'],
  mixins: [formatter],
  directives: {money: VMoney},
  data() {
    return {
      nameUser: '',
      isAdmin: false,
      isAdmRegister: false,
      loading: false,
      status: [],
      situation: [],
      ratings: [],
      service: null,
      closet: null,
      piscina: null,
      quartoEmp: null,
      churrasqueira: null,
      maquinas: null,
      projetoObra: null,
      options: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],

      listUf: [],
      listProjects: [],
      listCompanies: [],
      ImmobileTypes: [],
      immobileDocumentStatus: [],
      immobileDocumentType: [],
      error: '',
      disableChild: this.$route.params.disable ? 1 : 0,
      disableProject: this.$route.params.disableProject ? 1 : 0,

      companyAbout: '',
      required,
      min,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      notify: {
        data_essential_notify: false,
        photos_notify: false,
        bluePrint_notify: false,
        bluePrintAll_notify: false
      },
    }
  },

  props: ['formData', 'externalFileData', 'deliberation', 'publication'],

  methods: {
    async getSituationList() {
      this.situation = [
        { value: 1, text: 'Aprovado' },
        { value: 2, text: 'Aguardando' },
        { value: 4, text: 'Reprovado' },
        { value: 5, text: 'Assinatura Pendente' },
      ]
    },

    async getStatusList() {
      this.status = [
        { value: 1, text: 'Andamento' },
        { value: 2, text: 'Encerrado' },
        { value: 3, text: 'Vendido' },
      ]
    },

    formatCurrencyPrice(value) {
      value.replace('.','').replace(',','')
    },

    async validate() {
      const valid = (await this.$refs.validateForm.validate()) 
      const tabsValid = this.$refs.data_form_external.validate()
      return valid && tabsValid
    },

    isAdm() {
      return localStorage.getItem('role') === 'Admin'
    },

    async getRatingList() {
      try {
        const p = await this.$http.get('/api/services/app/ImmobileRatings/GetAll')
        this.ratings = p.data.result.items.map(m => ({ value: m.immobileRating.id, text: m.immobileRating.name }))
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter a lista de ratings de imóvel')
      }
    },

    async getSystemEstate() {
      const responseEstates = await this.$http.get('/api/services/app/Estates/GetAll', {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })

      this.listUf = responseEstates.data.result.items.map(res => ({
        value: res.estate.id,
        text: res.estate.name,
      }))
    },
    async getSystemProjects() {
      let filterCompany = ''
      if(this.formData.companyId !== undefined) {
        filterCompany = `?UserConstructionCompanyId=${this.formData.companyId}`
      }
      const responseProjects = await this.$http.get(`/api/services/app/Projects/GetAll${filterCompany}`, {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })

      this.listProjects = responseProjects.data.result.items.map(res => ({
        value: res.project.id,
        text: res.project.name,
      }))
    },
    async getSystemCompanies() {
      const response = await this.$http.get('/api/services/app/UserConstructionCompanies/GetAll?SituationFilter=1&MaxResultCount=1000', {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })

      this.listCompanies = response.data.result.items.map(res => ({
        value: res.userConstructionCompany.id,
        text: res.userConstructionCompany.name,
      }))
    },

    async getSystemImmobileTypes() {
      const ImmobileTypes = await this.$http.get('/api/services/app/ImmobileTypes/GetAll', {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })

      this.ImmobileTypes = ImmobileTypes.data.result.items.map(res => ({
        value: res.immobileType.id,
        text: res.immobileType.name,
      }))
    },

    async getSystemImmobileDocumentStatus() {
      const r = await this.$http.get('/api/services/app/ImmobileDocumentStatuses/GetAll', {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })

      this.immobileDocumentStatus = r.data.result.items.map(res => ({
        value: res.immobileDocumentStatus.id,
        text: res.immobileDocumentStatus.name,
        code: res.immobileDocumentStatus.code,
      }))
    },

    async getDataImmobiles() {
      this.loading = true
      const form = this.formData

     

      const dataImmobiles = await this.$http
        .get(`/api/services/app/Immobiles/GetImmobileForEdit?Id=${form.id}`, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)

      this.formData.immobileAddressId = dataImmobiles.data.result.immobile.immobileAddressId
      const dataImmobileAddresses = await this.getDataImmobileAddresses()

      await this.getFiles()

      this.setDataImmobile(dataImmobiles, dataImmobileAddresses)
      this.loading = false
    },

    async getFiles() {
      await this.getDataImmobileBluePrints()
      await this.getDataImmobilePhotos()
      await this.getDataImmobileDocuments()
    },

    async getDataImmobileAddresses() {
      const result = await this.$http
        .get(`/api/services/app/ImmobileAddresses/GetImmobileAddressForEdit?Id=${this.formData.immobileAddressId}`, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)

      return result
    },
    format(value) {
      let val = +value
      document.querySelector('.textfield_label').textContent = val.toLocaleString('fullwide', {maximumFractionDigits:2, style:'currency', currency:'BRL', useGrouping:true}) 
    },

    setDataImmobile(dataImmobiles, dataImmobileAddresses) {


      if (dataImmobiles) {
        const data = dataImmobiles.data.result.immobile
        this.formData = data
        this.companyAbout = dataImmobiles.data.result.aboutConstructionCompany
        this.valueReceived = dataImmobiles.data.result.valueReceived
        this.$emit('formDataChanged', this.formData)
      }
      if (dataImmobileAddresses) {
        this.formData.cepNumber = dataImmobileAddresses.data.result.immobileAddress.cepNumber.toString().replace(/(\d{5})?(\d{2})/, '$1-$2')
        this.formData.streetName = dataImmobileAddresses.data.result.immobileAddress.streetName
        this.formData.number = dataImmobileAddresses.data.result.immobileAddress.number
        this.formData.complement = dataImmobileAddresses.data.result.immobileAddress.complement
        this.formData.districtName = dataImmobileAddresses.data.result.immobileAddress.districtName
        this.formData.cityName = dataImmobileAddresses.data.result.immobileAddress.cityName
        this.formData.estateId = dataImmobileAddresses.data.result.immobileAddress.estateId
        this.formData.immobileAddressId = dataImmobileAddresses.data.result.immobileAddress.id
        this.formData.quotaDeadlineSell50 = formatter.flatPickrDateFormat(this.formData.quotaDeadlineSell50)
        this.formData.publishDate = formatter.flatPickrDateFormat(this.formData.publishDate)
        this.formData.keyDelivery = formatter.flatPickrDateFormat(this.formData.keyDelivery)
      }
    },

    updateValueReceived(value) {
      if (value == null) {
        // eslint-disable-next-line no-param-reassign
        value = 0
      }
      this.formData.valueReceived = value
    },

    updateExpectedRemuneration(value) {
      this.formData.expectedRemuneration = value
    },

    updateEstimatedProfitability(value) {
      this.formData.estimatedProfitability = value
    },

    updateQuotaValue(value) {
      this.formData.quotaValue = value
    },

    async getDataImmobileBluePrints() {
      const result = (await ImmobileBluePrintsService.getAll(this.formData.id))
      this.externalFileData.bluePrint = result.data.result.items
    },
    async getDataImmobilePhotos() {
      const r = await ImmobilePhotosService.getAll(this.formData.id)
      this.externalFileData.attachments = r.data.result.items
    },
    async getDataImmobileDocuments() {
      const r = (await ImmobileDocumentsService.getAll(this.formData.id))
      this.externalFileData.document = r.data.result.items.filter(d => d.immobileDocumentTypeCode === 0)
      this.externalFileData.documentosContratuais = r.data.result.items.filter(d => d.immobileDocumentTypeCode === 1)
    },

    async getDataCompanyInfo() {
      const r = await this.$http
        .get('/api/services/app/UserConstructionCompanyInfos/GetInfoByIdCompany', {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)
      if (r.data.result) {
        this.companyAbout = r.data.result.about
      }
    },

    formatPriceBR(i) {
      const x = i.target ? i.target.value : i
      let v = x.toString().replace(/\D/g, '')
      v = `${(v / 100).toFixed(2)}`
      v = v.replace('.', ',')
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
      v = v.replace(/(\d)(\d{3}),/g, '$1.$2,')
      return v
    },
  },

  async mounted() {
    // eslint-disable-next-line no-cond-assign
    if (this.nameUser = localStorage.getItem('role') === 'Admin') {
      this.isAdmin = true
    }
    console.log(this.isAdmin)

    this.getSystemEstate()
    this.getSystemImmobileTypes()
    this.getSystemProjects()
    if (this.isAdmRegister) {
      this.getSystemCompanies()
    }
    localStorage.removeItem('isAdmRegister')
    this.getSystemImmobileDocumentStatus()
    this.getStatusList()
    this.getSituationList()
    this.getRatingList()

    if (this.formData.id !== '' && this.formData.id !== null) {
      this.getDataImmobiles()
    }
    // console.log(this.$route.params.company)
  },
}
</script>
